export const environment = {
	/*
	*	L
	*	O
	*	C
	*	A
	*	L
	*/
    // apiUrl: "http://localhost:8081/v1"
    
    /*
    *   U
    *	A
    *	T
    */
    apiUrl: "https://service.umesystem.com.br/v1"

    /*
    *   P
    *	R
    *	O
    *	D
    */
    // apiUrl: "https://service.integplatform.com.br/"
}